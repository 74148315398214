/**
 * Site header
 */
.site-header {
    min-height: 56px;
    background: #fff;
    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    font-weight: 300;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $text-color;
        text-decoration: none!important;
    }
}

.site-nav {
    float: right;
    line-height: 56px;
    font-weight: bold;

    a {
      text-decoration: none!important;
    }

    .menu-icon {
        display: none;
    }

    ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    }

    li {
        float: left;
    }

    li a {
        display: block;
        color: #666;
        text-align: center;
        padding: 0px 15px;
        text-decoration: none;
    }

    li a:hover:not(.active) {
        font-weight: bold;
        color: $brand-color;
        transition:all 0.3s ease-out;
        -webkit-transition:all 0.3s ease-out;
        -moz-transition:all 0.3s ease-out;
        -ms-transition:all 0.3s ease-out;
        -o-transition:all 0.3s ease-out;
    }

    li a.active {
        font-weight: bold;
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
            transition:all 0.3s ease-out;
            -webkit-transition:all 0.3s ease-out;
            -moz-transition:all 0.3s ease-out;
            -ms-transition:all 0.3s ease-out;
            -o-transition:all 0.3s ease-out;
        }

        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}

.site-nav-home {
    text-align: center;
    line-height: 56px;
    font-weight: bold;

    a {
      text-decoration: none!important;
    }

    ul {
    display: inline-block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    }

    li {
        float: left;
    }

    li a {
        display: block;
        color: #666;
        text-align: center;
        padding: 0px 15px;
        text-decoration: none;
    }

    li a:hover:not(.active) {
        font-weight: bold;
        color: $brand-color;
        transition:all 0.3s ease-out;
        -webkit-transition:all 0.3s ease-out;
        -moz-transition:all 0.3s ease-out;
        -ms-transition:all 0.3s ease-out;
        -o-transition:all 0.3s ease-out;
    }

    li a.active {
        font-weight: bold;
    }
}

/**
 * Site footer
 */
.site-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /*border-top: 1px solid $grey-color-light;*/
    padding: $spacing-unit 20px 20px 20px;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    margin-bottom: 5em;
    padding-top: 15px;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;

    &,
    &:visited {
        color: $text-color;
        text-decoration: none!important;
    }
    &:hover {
      color: $brand-color;
      transition:all 0.3s ease-out;
      -webkit-transition:all 0.3s ease-out;
      -moz-transition:all 0.3s ease-out;
      -ms-transition:all 0.3s ease-out;
      -o-transition:all 0.3s ease-out;
    }
}



/**
 * Posts
 
.post-header {
    margin-bottom: $spacing-unit;
}
*/

.post-title {
    font-size: 30px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }

    img {
      max-height: 400px;
    }

    a {
      text-decoration: underline;
    }

    a.btn {
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        border: none;
        margin-top: 10px;
        margin-bottom: 10px;
        text-decoration: none;
        color: #fff;
    }
}

.heading-home {
  text-align:center;
  margin-bottom:0px;
  color: $text-color;
  font-size: 30px;
}

.caption {
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 0px;
    font-size: 12px;
    font-style: italic;
}

.herrbrueckers {
  background: url('https://adnan.id/img/mysketch.svg');
  width: 220px;
  height: 220px;
  display: block;
  position: relative;
  margin: 0 auto;
  margin-top:100px;
  margin-bottom: 10px;

  &%eyes {
    content: "";
    position: absolute;
    display: block;
    border-radius: 50%;
    height: 12px;
    width: 6px;
    background: $main_color;
    top: 50%;
    animation: blinkiblinki 6s 1s infinite linear alternate;
  }

  &:after {
    @extend %eyes;
    right: 38%;
  }

  &:before {
    @extend %eyes;
    left: 38%;
  }

}

@keyframes blinkiblinki {
  0% {height: 12px; top: 50%;}
  10% {height: 12px; top: 50%;}
  11% {height: 2px; top: 52%;}
  13% {height: 2px; top: 52%;}
  14% {height: 12px; top: 50%;}
  30% {height: 12px; top: 50%;}
  34% {height: 12px; top: 50%;}
  36% {height: 2px; top: 52%;}
  38% {height: 12px; top: 50%;}
  40% {height: 2px; top: 52%;}
  42% {height: 12px; top: 50%;}
  99.9999% {height: 12px; top: 50%;}
}

@-webkit-keyframes blink {
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes blink {
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@keyframes blink {
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}

//for hr
.shadow {
  height: 12px;
  border: 0;
  box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);
}

//
.card-panel, .card {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.card-panel {
  transition: box-shadow .25s;
  padding: 10px;
  margin: 0.5rem 0 1rem 0;
  border-radius: 2px;
  background-color: #fff;
}

.card {
  position: relative;
  margin: 0.5rem 0 1rem 0;
  background-color: #fff;
  transition: box-shadow .25s;
  border-radius: 2px;
}

.card .card-title {
  font-size: 24px;
  font-weight: 300;
}

.card .card-title.activator {
  cursor: pointer;
}

.card.small, .card.medium, .card.large {
  position: relative;
}

.card.small .card-image, .card.medium .card-image, .card.large .card-image {
  max-height: 60%;
  overflow: hidden;
}

.card.small .card-content, .card.medium .card-content, .card.large .card-content {
  max-height: 40%;
  overflow: hidden;
}

.card.small .card-action, .card.medium .card-action, .card.large .card-action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.card.small {
  height: 300px;
}

.card.medium {
  height: 400px;
}

.card.large {
  height: 500px;
}

.card .card-image {
  position: relative;
}

.card .card-image img {
  display: block;
  border-radius: 2px 2px 0 0;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.card .card-image .card-title {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
}

.card .card-content {
  padding: 20px;
  border-radius: 0 0 2px 2px;
}

.card .card-content p {
  margin: 0;
  color: inherit;
}

.card .card-content .card-title {
  line-height: 48px;
}

.card .card-action {
  position: relative;
  background-color: inherit;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  padding: 20px;
  z-index: 2;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-floating) {
  color: #2ecc71;
  margin-right: 20px;
  transition: color .3s ease;
  text-transform: uppercase;
}

.card .card-action a:not(.btn):not(.btn-large):not(.btn-floating):hover {
  color: #208e4e;
}

.card .card-action + .card-reveal {
  z-index: 1;
  padding-bottom: 64px;
}

.card .card-reveal {
  padding: 20px;
  position: absolute;
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  top: 100%;
  height: 100%;
  z-index: 3;
  display: none;
}

.card .card-reveal .card-title {
  cursor: pointer;
  display: block;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: all .3s ease-out;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.7s ease-out;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-transform: scale(0);
          transform: scale(0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7);
}

.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7);
}

.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7);
}

.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7);
}

.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7);
}

.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7);
}

.waves-effect input[type="button"], .waves-effect input[type="reset"], .waves-effect input[type="submit"] {
  border: 0;
  font-style: normal;
  font-size: inherit;
  text-transform: inherit;
  background: none;
}

.waves-notransition {
  transition: none !important;
}

.waves-circle {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-block {
  display: block;
}

/* Firefox Bug: link not triggered */
a.waves-effect .waves-ripple {
  z-index: -1;
}

.toast .btn, .toast .btn-large, .toast .btn-flat {
  margin: 0;
  margin-left: 3rem;
}

.btn, .btn-large,
.btn-flat {
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  outline: 0;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.btn i, .btn-large i,
.btn-floating i,
.btn-large i,
.btn-flat i {
  font-size: 1.3rem;
  line-height: inherit;
}

.btn-flat {
  box-shadow: none;
  background-color: transparent;
  color: #343434;
  cursor: pointer;
}

.btn-flat.disabled {
  color: #b3b3b3;
  cursor: default;
}

.modal .modal-footer .btn, .modal .modal-footer .btn-large, .modal .modal-footer .btn-flat {
  float: right;
  margin: 6px 0;
}

.side-nav a.btn, .side-nav a.btn-large, .side-nav a.btn-large, .side-nav a.btn-flat, .side-nav a.btn-floating {
  margin: 10px 15px;
}

.side-nav a.btn-flat {
  color: #343434;
}

/* 404 Page */
.container-404 {
  text-align: center;
  vertical-align: middle;
}

.content-404 {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  padding-top: 100px;
}

.title-404 {
  color: #2ecc71;
  font-size: 140px;
}

.quote-404 {
  font-size: 25px;
}
